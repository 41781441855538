import React from 'react';
import styled from 'styled-components';
import CloseButton from './closeButton';
import Disclaimer from './disclaimer';
import Screen from './screen';

const Button = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0.5rem 0 0.75rem;
  position: relative;
  &:hover {
    opacity: 0.5;
  }
`;

const Counter = styled.div`
  align-items: center;
  background-color: #71c1e3;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  position: absolute;
  height: 1.25rem;
  width: 1.25rem;
  right: -0.5rem;
  top: -0.75rem;
`;

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  body & {
    overflow: hidden !important;
  }
`;

const Contents = styled.div`
  padding: 5rem 2rem 2rem;
  margin: 0 auto;
  max-width: 75rem;
`;

const ContentsItem = styled.tr`
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem 0;
`;

const EmptyMessage = styled.h4`
  color: #448cb7;
  padding: 10rem 0;
  text-align: center;
`;

const RemoveButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  svg {
    fill: #8a8a8a;
  }
  @media (min-width: 640px) {
    padding: 0 1rem;
  }
  @media print {
    display: none;
  }
`;

const Table = styled.table`
  width: 100%;
`;

const ActionButton = styled.button`
  appearance: none;
  background-color: #2d8db6;
  border: solid 1px #2d8db6;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
`;

const EmptyButton = styled(ActionButton)`
  background-color: #e12c2c;
  border: solid 1px #b62d2d;
  @media print {
    display: none;
  }
`;

const Cell = styled.td`
  padding: 0.5rem 0;
`;

const QuantityCell = styled(Cell)`
  width: 5rem;
  @media (min-width: 640px) {
    width: 6rem;
  }
`;

const ProductCell = styled(Cell)`
  padding-left: 1rem;
`;

const PriceCell = styled(Cell)`
  padding-left: 1rem;
  text-align: right;
  width: 6rem;
  @media (min-width: 640px) {
    width: 8rem;
  }
`;

const RemoveCell = styled(Cell)`
  width: 2rem;
  @media (min-width: 640px) {
    width: 3rem;
  }
`;

const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  margin: 1rem 0;
`;

const CartActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const trimCurrencySymbol = price =>
  isNaN(price.substring(0, 1))
    ? parseInt(price.substring(1), 10)
    : parseInt(price, 10);

const CartButton = ({
  cartContents,
  count,
  modalActive,
  setModalActive,
  handleOnClear,
  handleOnRemove,
}) => {
  const subtotal = cartContents
    ? cartContents.contents.reduce(
        (sum, content) =>
          sum + trimCurrencySymbol(content.Total_Price.replace(',', '')),
        0
      )
    : 0;
  const total = subtotal.toFixed(2);
  return (
    <div>
      <Button onClick={() => setModalActive(true)}>
        {count !== 0 && (
          <Counter>
            <span>{count}</span>
          </Counter>
        )}
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
      </Button>
      {modalActive && (
        <Container>
          <CloseButton handleOnClick={() => setModalActive(false)} />
          <Contents>
            <Heading>Quote</Heading>
            {count > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <QuantityCell>
                      <strong>Quantity</strong>
                    </QuantityCell>
                    <ProductCell>
                      <strong>Product</strong>
                    </ProductCell>
                    <PriceCell>
                      <strong>Price</strong>
                    </PriceCell>
                  </tr>
                </thead>
                <tbody>
                  {cartContents.contents.map(content => (
                    <ContentsItem key={`${content.BB_SKU}-${content.Quantity}`}>
                      <QuantityCell>{content.Quantity}</QuantityCell>
                      <ProductCell>{content.Product_Name}</ProductCell>
                      <PriceCell>{content.Total_Price}</PriceCell>
                      <RemoveCell>
                        <RemoveButton
                          type="button"
                          onClick={() => handleOnRemove(content)}
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                          </svg>
                        </RemoveButton>
                      </RemoveCell>
                    </ContentsItem>
                  ))}
                  <ContentsItem>
                    <QuantityCell />
                    <ProductCell />
                    <PriceCell />
                  </ContentsItem>
                  <ContentsItem>
                    <QuantityCell />
                    <ProductCell>
                      <strong>Total</strong>
                    </ProductCell>
                    <PriceCell>
                      <strong>
                        {cartContents.contents.length
                          ? cartContents.contents[0].Total_Price.substring(0, 1)
                          : ''}
                        {total}
                      </strong>
                    </PriceCell>
                    <RemoveCell />
                  </ContentsItem>
                </tbody>
              </Table>
            ) : (
              <EmptyMessage>Empty Cart</EmptyMessage>
            )}
            <CartActions>
              {count > 0 && (
                <EmptyButton
                  type="button"
                  onClick={() => {
                    handleOnClear();
                    setModalActive(false);
                  }}
                >
                  Empty Cart
                </EmptyButton>
              )}
            </CartActions>
            {count > 0 && <Disclaimer />}
          </Contents>
        </Container>
      )}
      {modalActive && <Screen onClick={() => setModalActive(false)} />}
    </div>
  );
};

export default CartButton;
