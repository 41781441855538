import React from 'react';
import styled from 'styled-components';

const SmallPrint = styled.p`
  color: #cdcdcd;
  font-size: 0.75rem;
  text-align: right;
  margin-top: 2rem;
`;

const Disclaimer = ({
  copy = 'All prices shown are non-binding estimates only. Please contact your representative for a firm quote and further details.',
}) => <SmallPrint>{copy}</SmallPrint>;

export default Disclaimer;
