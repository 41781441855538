import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  appearance: none;
  border: none;
  background-color: #dcdcdc;
  border-radius: 50%;
  color: #454545;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  &:active {
    outline: none;
  }
  &:hover {
    opacity: 0.7;
  }
  svg {
    fill: #448cb7;
  }
  @media (min-width: 640px) {
    right: 1.25rem;
    top: 1.25rem;
    height: 2rem;
    width: 2rem;
  }
  @media (min-width: 940px) {
    right: 1.5rem;
    top: 1.75rem;
  }
  @media print {
    display: none;
  }
`;

const CloseButton = ({ handleOnClick }) => (
  <Button onClick={handleOnClick}>
    <svg viewBox="0 0 24 24">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  </Button>
);

export default CloseButton;
