import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout
    Content={() => (
      <div>
        <h1>Not found</h1>
      </div>
    )}
  />
);

export default NotFoundPage;
