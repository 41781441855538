import styled from 'styled-components';

const Screen = styled.div`
  background: rgba(255, 255, 255, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
`;

export default Screen;
