import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { lifecycle, compose, withState, withHandlers } from 'recompose';
import CartButton from './cartButton';
import Select from './select';
import Logo from './logo';

const Container = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 0.75rem;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  padding: 1.5rem 1rem;
`;

const Toast = styled.div`
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #448cb7;
  color: #448cb7;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
`;

const Header = ({
  cartContents,
  handleLocaleChange,
  handleOnClear,
  locale,
  toastActive,
  handleOnRemove,
  modalActive,
  setModalActive,
}) => (
  <Container>
    <Link to={`/${locale}`}>
      <Logo />
    </Link>
    <Actions>
      <Select
        value={locale || 'us'}
        handleOnChange={handleLocaleChange}
        label="Locale"
        options={[
          {
            value: 'us',
            label: 'US',
          },
          {
            value: 'uk',
            label: 'UK',
          },
          {
            value: 'ca',
            label: 'CA',
          },
          {
            value: 'fr',
            label: 'FR',
          },
        ]}
      />
      <CartButton
        cartContents={cartContents}
        count={
          cartContents && cartContents.contents
            ? cartContents.contents.length
            : 0
        }
        handleOnClear={handleOnClear}
        handleOnRemove={handleOnRemove}
        modalActive={modalActive}
        setModalActive={setModalActive}
      />
    </Actions>
    {toastActive && <Toast>Added!</Toast>}
  </Container>
);

export default compose(
  withState('locale', 'setLocale', null),
  withHandlers({
    handleLocaleChange: () => event => {
      const newLocale = event.target.value;
      if (typeof window !== 'undefined') {
        localStorage.setItem('locale', newLocale);
        window.location.pathname = `/${newLocale}/`;
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      let locale = localStorage.getItem('locale');
      if (!locale) {
        locale = 'us';
      }
      this.props.setLocale(locale);
    },
  })
)(Header);
