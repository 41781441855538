import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { compose, lifecycle, withState, withHandlers } from 'recompose';
import Header from './header';
import 'sanitize.css';

const LayoutContainer = styled.div`
  margin: 0 auto;
  padding: 0 0.75rem;
  position: relative;
`;

const Layout = ({ Content, ...props }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Vistaprint Corporate Pricing' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header
            siteTitle={data.site.siteMetadata.title}
            cartContents={props.cartContents}
            modalActive={props.modalActive}
            setModalActive={props.setModalActive}
            handleOnClear={props.handleOnClear}
            toastActive={props.toastActive}
            handleOnRemove={props.handleOnRemove}
          />
          {!props.modalActive && (
            <LayoutContainer>
              <Content {...props} siteData={data} />
            </LayoutContainer>
          )}
        </>
      );
    }}
  />
);

Layout.propTypes = {
  Content: PropTypes.func.isRequired,
};

export default compose(
  withState('modalActive', 'setModalActive', false),
  withState('cartContents', 'updateCartContents', null),
  withState('toastActive', 'setToastActive', false),
  withHandlers({
    handleOnAdd: props => node => {
      if (typeof window !== 'undefined') {
        const cart = JSON.parse(localStorage.getItem('cart'));
        const currentContents = cart && cart.contents ? cart.contents : [];
        const updatedCart = { contents: [...currentContents, node] };

        props.updateCartContents(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        props.setToastActive(true);
        setTimeout(() => {
          props.setToastActive(false);
        }, 2000);
      }
    },
    handleOnClear: props => () => {
      if (typeof window !== 'undefined') {
        localStorage.removeItem('cart');
        props.updateCartContents(null);
      }
    },
    handleOnRemove: props => node => {
      if (typeof window !== 'undefined') {
        const cart = JSON.parse(localStorage.getItem('cart'));
        const updatedCart = {
          contents: cart.contents.filter(
            item =>
              `${item.BB_SKU}-${item.Quantity}` !==
              `${node.BB_SKU}-${node.Quantity}`
          ),
        };

        props.updateCartContents(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      if (typeof window !== 'undefined') {
        this.props.updateCartContents(JSON.parse(localStorage.getItem('cart')));
      }
    },
  })
)(Layout);
