import React from 'react';
import styled from 'styled-components';

export const SelectInput = styled.select`
  appearance: none;
  background-color: transparent;
  border-bottom: solid 1px #448cb7;
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: none;
  color: #448cb7;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 400;
  max-width: 20rem;
  padding: 0 1.5rem 0.25rem 0;
  position: relative;
  z-index: 5;
  &:focus {
    outline: none;
  }

  @media (min-width: 640px) {
    font-size: 1rem;
    max-width: 18rem;
  }
`;

const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const Arrow = styled.div`
  padding: 1rem 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 3;
  svg {
    fill: #448cb7;
    height: 1.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 1.5rem;
  }
`;

const Label = styled.label`
  color: #454545;
  font-size: 0.75rem;
  padding-right: 0.5rem;
  @media (min-width: 640px) {
    padding-right: 1rem;
  }
`;

const Select = ({ label, value, handleOnChange, options, OptionComponent }) => (
  <SelectContainer>
    {label && <Label>{label}</Label>}
    <SelectInput onChange={handleOnChange} value={value}>
      {options &&
        options.map(o => (
          <option key={o.value} value={o.value}>
            {OptionComponent ? <OptionComponent /> : o.label}
          </option>
        ))}
    </SelectInput>
    <Arrow>
      <svg viewBox="0 0 24 24">
        <path d="M7 10l5 5 5-5z" />
      </svg>
    </Arrow>
  </SelectContainer>
);

export default Select;
